/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-param-reassign, import/extensions, import/no-unresolved */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { makeRemoteDistributionList, makeRemoteDistributions } from 'src/main/usecases/distributions/distributions'

export interface DistributionsStateInterface {
  list: any,
  data: any,
  loading: boolean,
  loaded: boolean,
  loadingList: boolean,
  loadedList: boolean,
  size?: number,
  page?: number,
  totalPages?: number,
  totalElements?: number,
  query?: string,
  ano?: string,
  mes?: string,
  divisions: string[] | [],
  listaProdutos: string[]
}

// Define the initial state using that type
const initialState: DistributionsStateInterface = {
  list: [],
  data: [],
  loading: true,
  loadingList: true,
  loadedList: false,
  loaded: false,
  divisions: [],
  listaProdutos: [],
}

export interface DistributionsParamsProps {
  query?: string,
  size?: number,
  page?: number,
  divisoes?: string[],
  ano?: string,
  mes?: string,
  listaProdutos?: string[]
}

export const fetchDistributionsList = createAsyncThunk(
  'distributions-list/index',
  async (params: DistributionsParamsProps, thunkAPI) => {
    thunkAPI.dispatch(distributionsListLoading())
    const { listaProdutos, ...newParams } = params
    const response = makeRemoteDistributionList().update({ listaProdutos }, newParams)
    return response
  },
)

export const fetchDistributions = createAsyncThunk(
  'distributions-index/index',
  async (params: DistributionsParamsProps) => {
    delete params.listaProdutos
    const response = makeRemoteDistributions().index(params)
    return response
  },
)

const slice = createSlice({
  name: 'distruibutions',
  initialState,
  reducers: {
    distributionsLoading(state) {
      state.loading = true
    },
    distributionsListLoading(state) {
      state.loadingList = true
    },
    distributionsQuery(state, action) {
      state.query = action.payload
    },
    distributionsDivisions(state, action) {
      state.divisions = action.payload
    },
    distributionsFilters(state, action) {
      state.divisions = action.payload.divisoes
      state.query = action.payload.query
      state.ano = action.payload.ano
      state.mes = action.payload.mes
      state.listaProdutos = action.payload.listaProdutos
    },

  },

  extraReducers: (builder) => {
    builder.addCase(fetchDistributions.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchDistributions.fulfilled, (state, action) => {
      state.list = action.payload.content
      state.size = action.payload.size
      state.page = action.payload.number
      state.totalPages = action.payload.totalPages
      state.totalElements = action.payload.totalElements
      state.loading = false
      state.loaded = true
    })
    builder.addCase(fetchDistributionsList.fulfilled, (state, action) => {
      state.data = action.payload.content
      state.size = action.payload.size
      state.page = action.payload.number
      state.totalPages = action.payload.totalPages
      state.totalElements = action.payload.totalElements
      state.loadingList = false
    })
  },
})

export const {
  distributionsLoading,
  distributionsDivisions,
  distributionsFilters,
  distributionsQuery,
  distributionsListLoading,
} = slice.actions

export default slice.reducer
