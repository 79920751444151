export const PAGES_SIDE_BAR = [
  {
    title: 'Início',
    icon: 'RiHome5Line',
    path: '/',
  },
  {
    title: 'Dashboard',
    icon: 'RiDashboardLine',
    hasDropdown: [
      {
        title: 'Metas',
        icon: 'RiDashboardLine',
        path: '/dashboard-metas',
      },
      {
        title: 'KPI',
        icon: 'RiDashboardLine',
        path: '/dashboard-kpi',
      },
      {
        title: 'Relatório Abertura de Cotas',
        icon: 'RiDashboardLine',
        path: '/dashboard-relatorio',
      },
      {
        title: 'Cotas x Metas',
        icon: 'RiDashboardLine',
        path: '/dashboard-cotas-metas',
      },
    ],
  },
  {
    title: 'Consulta de cliente',
    icon: 'RiUserSearchLine',
    path: '/consulta-clientes',
  },
  {
    title: 'Regras do programa',
    icon: 'RiRefundLine',
    path: '/regras-programa',
  },
  {
    title: 'Configurações',
    icon: 'RiSettings4Line',
    hasDropdown: [
      {
        title: 'Usuários',
        icon: 'RiUserAddLine',
        path: '/usuarios',
      },
      {
        title: 'Grupo de aprovações',
        icon: 'RiGroupLine',
        path: '/grupo-aprovacoes',
      },
      {
        title: 'Apurações de metas',
        icon: 'RiFileDamageLine',
        path: '/apuracoes-metas',
      },
      {
        title: 'Desconto por canal',
        icon: 'RiListUnordered',
        path: '/classificacoes',
      },
      {
        title: 'Tipos (De-Para)',
        icon: 'RiListSettingsLine',
        path: '/tipos',
      },
      {
        title: 'Distribuidores',
        icon: 'RiLuggageCartLine',
        path: '/configuracoes/distribuidores',
      },
      {
        title: 'Regras de programa',
        icon: 'RiRefundLine',
        path: '/configuracoes/regras-programa',
      },
    ],
  },
  {
    title: 'Cotas',
    icon: 'RiDraftLine',
    path: '/cotas',
  },
  {
    title: 'Meta cliente',
    icon: 'RiFocus2Line',
    path: '/meta-cliente',
  },
  {
    title: 'Produtos para bonificação',
    icon: 'RiShoppingBagLine',
    path: '/produtos-para-bonificacao',
  },
  {
    title: 'Distribuidores',
    icon: 'RiLuggageCartLine',
    path: '/distribuidores',
  },
  {
    title: 'Importação',
    icon: 'RiFileExcel2Line',
    path: '/importacao',
  },
  {
    title: 'Gerar Apuração',
    icon: 'RiSearchEyeLine',
    path: '/gerar-apuracao',
  },
  {
    title: 'Atualizar Apuração',
    icon: 'RiRefreshLine',
    path: '/atualizar-apuracao',
  },
  {
    title: 'Desconto em duplicata',
    icon: 'RiHandCoinLine',
    path: '/desconto-em-duplicata',
  },
  {
    title: 'Pedidos de resgate',
    icon: 'RiExchangeDollarLine',
    path: '/pedido-resgate',
  },
  {
    title: 'Aprovação de pedido',
    icon: 'RiCheckboxMultipleLine',
    path: '/aprovacao-pedido',
  },
  {
    title: 'Saldos',
    icon: 'RiMoneyDollarCircleLine',
    path: '/saldos',
  },
  {
    title: 'Aceite Regras de Programa',
    icon: 'RiCheckboxLine',
    path: '/aceite-regras-programa',
  },
]
