/* eslint-disable max-len */
import { RemoteGoalCalculations } from '../../../data/usecases/goal-calculations/goal-calculation'
import { makeAxiosHttpClient } from '../../factories/http/axios-http-client-factory'
import { makeAxiosHttpClient as NoRedirec } from '../../factories/http/axios-http-client-factory-no-redirect'

export const makeRemoteGoalCalculations = ():any => new RemoteGoalCalculations('/configuracaoperiodo', makeAxiosHttpClient())

export const makeRemoteAllGoalCalculations = ():any => new RemoteGoalCalculations('/configuracaoperiodo/all', makeAxiosHttpClient())

export const makeRemoteCloneGoalCalculations = ():any => new RemoteGoalCalculations('/configuracaoperiodo/duplicate', NoRedirec())

export const makeRemoteGoalCalculation = (id:number):any => new RemoteGoalCalculations(`/configuracaoperiodo/${id}`, NoRedirec())

export const makeRemoteGoalCalculationTypeCreate = ():any => (
  new RemoteGoalCalculations('/configuracaoperiodo/tipo/', NoRedirec())
)

export const makeRemoteGoalCalculationType = (id:number, divisao: string):any => (
  new RemoteGoalCalculations(`/configuracaoperiodo/tipo/${id}/${divisao}`, NoRedirec())
)

export const makeRemoteGoalCalculationClient = (id:number, divisao: string, toDelete?:boolean):any => (
  new RemoteGoalCalculations(`/configuracaoperiodo/${id}/${divisao}/cliente${toDelete ? '/delete' : ''}`, NoRedirec())
)

export const makeRemoteGoalCalculationItem = (id:number, divisao: string, toDelete?:boolean):any => (
  new RemoteGoalCalculations(`/configuracaoperiodo/${id}/${divisao}/item${toDelete ? '/delete' : ''}`, NoRedirec())
)
export const makeRemoteGoalCalculationProdutoEstrategico = (id:number, divisao: string, toDelete?:boolean):any => (
  new RemoteGoalCalculations(`/configuracaoperiodo/${id}/${divisao}/produtoestrategico${toDelete ? '/delete' : ''}`, NoRedirec())
)

export const makeRemoteAllClientGroups = (paged:boolean):any => new RemoteGoalCalculations(`/grupo/all${paged ? '/paged' : ''}`, makeAxiosHttpClient())

export const makeRemoteAllClientGroupsNoPagination = ():any => new RemoteGoalCalculations('/grupo/all', makeAxiosHttpClient())

export const makeRemoteClientGroupsByBu = (divisao: string):any => new RemoteGoalCalculations(`/grupo/bydivisao/${divisao}`, makeAxiosHttpClient())

export const makeRemoteAllSkuItems = (paged:boolean):any => new RemoteGoalCalculations(`/item/distinct${paged ? '/paged' : ''}`, NoRedirec())

export const makerRemoteReferenceData = (year?: number, divisao?: string):any => (
  new RemoteGoalCalculations(`/configuracaoperiodo/datareferencia${year ? `/${year}/${divisao}` : ''}`, NoRedirec())
)

export const makeRemoteAllMarcaItems = (paged:boolean):any => (
  new RemoteGoalCalculations(`/marca${paged ? '/paged' : ''}`, NoRedirec())
)

export const makeRemoteMarcaByDivision = ():any => (
  new RemoteGoalCalculations('/marca/divisao', NoRedirec())
)
