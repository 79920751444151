/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-param-reassign, import/extensions, import/no-unresolved */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { makeRemoteType, makeRemoteTypeAll } from 'src/main/usecases/types/types'

export interface TypesStateInterface {
  list: {
    id: number,
    nome: string,
    tipoclientes: string[]
  }[];
  loading: boolean;
  loaded: boolean;
  size?: number;
  page?: number;
  totalPages?: number;
  totalElements?: number;
  query?: string;
  sort?: string;
  order?: string;
}

// Define the initial state using that type
const initialState: TypesStateInterface = {
  list: [],
  loading: true,
  loaded: false,
}

export interface TypesParamsInterface {
  page?: number;
  size?: number;
  sort?: string;
  query?: string;
}

export const fetchAllTypes = createAsyncThunk(
  'types-all/index',
  async () => {
    const response = await makeRemoteTypeAll().index()
    return response
  },
)

export const fetchTypes = createAsyncThunk(
  'types/index',
  async (params: TypesParamsInterface, thunkAPI) => {
    thunkAPI.dispatch(typesLoading())

    const response = await makeRemoteType().index(params)

    thunkAPI.dispatch(typesReceived())
    thunkAPI.dispatch(typesLoaded())
    return response
  },
)

const slice = createSlice({
  name: 'types',
  initialState,
  reducers: {
    typesLoaded(state) {
      state.loaded = true
    },
    typesLoading(state) {
      if (!state?.loading) {
        state.loading = true
      }
    },
    typesReceived(state) {
      if (state.loading) {
        state.loading = false
      }
    },
    typesFilter(state, action) {
      state.query = action.payload.query
      if (action.payload.size) {
        state.size = action.payload.size
      }
      if (action.payload.page) {
        state.page = action.payload.page
      }
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchTypes.fulfilled, (state, action) => {
      state.list = action.payload.content
      state.size = action.payload.size
      state.page = action.payload.number
      state.totalPages = action.payload.totalPages
      state.totalElements = action.payload.totalElements
    })
    builder.addCase(fetchAllTypes.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchAllTypes.rejected, (state) => {
      state.loading = false
      state.loaded = false
    })
    builder.addCase(fetchAllTypes.fulfilled, (state, action) => {
      state.list = action.payload
      state.loading = false
      state.loaded = true
    })
  },
})

export const {
  typesLoaded,
  typesLoading,
  typesReceived,
  typesFilter,
} = slice.actions

export default slice.reducer
