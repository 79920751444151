/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-param-reassign, import/extensions, import/no-unresolved */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { makeRemoteDivision, makeRemoteDivisionParent } from 'src/main/usecases/users/users'

export interface DivisionsStateInterface {
  divisions: any,
  loading: boolean,
  loaded: boolean,
}

interface DivisionsParamsInterface {
  parent?: string
}

// Define the initial state using that type
const initialState: DivisionsStateInterface = {
  divisions: [],
  loading: true,
  loaded: false,
}

export const fetchDivisions = createAsyncThunk(
  'divisions/index',
  async (params?: DivisionsParamsInterface) => {
    const response = await makeRemoteDivision().index(params)

    return response
  },
)

export const fetchDivisionsParent = createAsyncThunk(
  'divisions/parent',
  async (params: DivisionsParamsInterface, thunkAPI) => {
    thunkAPI.dispatch(divisionsLoading())

    const response = await makeRemoteDivisionParent().index(params)

    thunkAPI.dispatch(divisionsReceived())
    thunkAPI.dispatch(divisionsLoaded())
    return response
  },
)

const slice = createSlice({
  name: 'divisions',
  initialState,
  reducers: {
    divisionsLoaded(state) {
      state.loaded = true
    },
    divisionsLoading(state) {
      if (!state?.loading) {
        state.loading = true
      }
    },
    divisionsReceived(state) {
      if (state.loading) {
        state.loading = false
      }
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchDivisions.fulfilled, (state, action) => {
      state.divisions = action.payload
      state.loading = false
      state.loaded = true
    })
    builder.addCase(fetchDivisions.pending, (state) => {
      state.loading = true
      state.loaded = false
    })
    builder.addCase(fetchDivisions.rejected, (state) => {
      state.loading = false
      state.loaded = false
    })
    builder.addCase(fetchDivisionsParent.fulfilled, (state, action) => {
      state.divisions = action.payload
    })
  },
})

export const {
  divisionsLoaded,
  divisionsLoading,
  divisionsReceived,
} = slice.actions

export default slice.reducer
