/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-param-reassign, import/extensions, import/no-unresolved */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { BrandProps } from 'src/domain/models/brands'
import { HttpResponse } from 'src/domain/models/http-paged-response'
import { makeRemoteAllMarcaItems, makeRemoteMarcaByDivision } from '../../usecases/goal-calculations/goal-calculations'

export interface BrandsStateInterface {
  data?: HttpResponse.Paged<BrandProps.Model>,
  all?: HttpResponse.Array<BrandProps.Model>,
  loading: boolean,
  loaded: boolean,
}

// Define the initial state using that type
const initialState: BrandsStateInterface = {
  loading: true,
  loaded: false,
}

type TBrandsParams = {
  divisao?: string[],
  query?: string
}

export const fetchAllBrands = createAsyncThunk(
  'brand/index',
  async (params?:TBrandsParams) => {
    const response = await makeRemoteAllMarcaItems(false).index(params)

    return response
  },
)

export const fetchBrandsByDivision = createAsyncThunk(
  'brand/division',
  async (params?:TBrandsParams) => {
    const response = await makeRemoteMarcaByDivision().index(params)

    return response
  },
)

const slice = createSlice({
  name: 'brands',
  initialState,
  reducers: {
  },

  extraReducers: (builder) => {
    builder.addCase(fetchAllBrands.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchAllBrands.rejected, (state) => {
      state.loading = false
      state.loaded = false
    })
    builder.addCase(fetchAllBrands.fulfilled, (state, action) => {
      state.loading = false
      state.loaded = true
      state.all = action.payload
    })
    builder.addCase(fetchBrandsByDivision.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchBrandsByDivision.rejected, (state) => {
      state.loading = false
      state.loaded = false
    })
    builder.addCase(fetchBrandsByDivision.fulfilled, (state, action) => {
      state.loading = false
      state.loaded = true
      state.all = action.payload
    })
  },
})

export default slice.reducer
