/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-param-reassign, import/extensions, import/no-unresolved */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import {
  makeRemoteGoalCalculations,
  makeRemoteGoalCalculation,
  makeRemoteGoalCalculationClient,
  makeRemoteGoalCalculationItem,
  makeRemoteAllClientGroups,
  makeRemoteAllSkuItems,
  makeRemoteAllClientGroupsNoPagination,
  makeRemoteAllGoalCalculations,
  makeRemoteAllMarcaItems,
  makeRemoteGoalCalculationProdutoEstrategico,
} from 'src/main/usecases/goal-calculations/goal-calculations'

export const orderByYear = (a:any, b:any):number => {
  if (a?.ano > b?.ano) return 1
  return -1
}

export interface GoalCalculationYearProps {
  id: number,
  type: string,
}
export interface GoalCalculationsInterface {
  list: any[],
  allList: any[],
  selectedYear?: number, // page quotas

  loading: boolean,
  loaded: boolean,
  groups: any,
  loadingGroups: boolean,
  items: any
  loadingItems: boolean,
}

// Define the initial state using that type
const initialState: GoalCalculationsInterface = {
  list: [],
  loading: true,
  loaded: false,
  groups: [],
  loadingGroups: true,
  items: [],
  loadingItems: true,
  allList: [],
}

export interface GoalCalculationsParamsInterface {
  page?: number,
  size?: number,
  query?: string,
  sort?: string,
  divisao?: string | string[],
}

export const fetchGoalAllCalculations = createAsyncThunk(
  'goalAllCalculations/index',
  async (params: GoalCalculationsParamsInterface) => {
    const response = await makeRemoteAllGoalCalculations().index(params)
    return response
  },
)

export const fetchGoalCalculations = createAsyncThunk(
  'goalCalculations/index',
  async (params: GoalCalculationsParamsInterface, thunkAPI) => {
    thunkAPI.dispatch(goalCalculationLoading())

    const response = await makeRemoteGoalCalculations().index(params)

    thunkAPI.dispatch(goalCalculationReceived())
    thunkAPI.dispatch(goalCalculationLoaded())
    return response
  },
)

export const fetchGoalCalculationsAllGroupsNoPagination = createAsyncThunk(
  'goalCalculations/groups/all-no-pagination',
  async (params, thunkAPI) => {
    thunkAPI.dispatch(goalCalculationGroupsLoading(true))
    const response = await makeRemoteAllClientGroupsNoPagination().load()

    thunkAPI.dispatch(goalCalculationAllGroups(response))
    thunkAPI.dispatch(goalCalculationGroupsLoading(false))
    return response
  },
)

export const fetchGoalCalculationsAllGroups = createAsyncThunk(
  'goalCalculations/groups/all',
  async (params:GoalCalculationsParamsInterface, thunkAPI) => {
    const response = await makeRemoteAllClientGroups(params.page !== undefined).load(params)

    thunkAPI.dispatch(goalCalculationAllGroups(response))
    thunkAPI.dispatch(goalCalculationGroupsLoading(false))
    return response
  },
)

export const fetchGoalCalculationsAllItems = createAsyncThunk(
  'goalCalculations/items/all',
  async (params: GoalCalculationsParamsInterface, thunkAPI) => {
    const response = await makeRemoteAllSkuItems(params.page !== undefined).load(params)

    thunkAPI.dispatch(goalCalculationAllItems(response))
    thunkAPI.dispatch(goalCalculationItemsLoading(false))
    return response
  },
)

export const fetchGoalCalculationsAllItemsMarca = createAsyncThunk(
  'goalCalculations/items-marca/all',
  async (params: GoalCalculationsParamsInterface) => {
    const response = await makeRemoteAllMarcaItems(params.page !== undefined).load(params)

    return response
  },
)

export const fetchGoalCalculationYear = createAsyncThunk(
  'goalCalculations/year',
  async (params: any) => {
    const response = makeRemoteGoalCalculation(params.id).index()
    const clientsList = makeRemoteGoalCalculationClient(params.id, params.divisao).index()
    const productList = makeRemoteGoalCalculationItem(params.id, params.divisao).index()
    const produtosEstrategicosList = makeRemoteGoalCalculationProdutoEstrategico(params.id, params.divisao).index()
    const responses = await axios.all([response, clientsList, productList, produtosEstrategicosList])

    return {
      ...responses[0],
      clientsList: responses[1].map((item:any) => ({
        id: item.id,
        codigo: item.codigoGrupo,
        name: item.nomeGrupo,
      })),
      productList: responses[2].map((item:any) => ({
        id: item.id,
        codigo: item.numeroItem,
        name: item.descricaoItem,
        tipoId: item.tipoId,
        tipoNome: item.tipoNome,
      })),
      produtosEstrategicosList: responses[3].map((item:any) => ({
        id: item.id,
        codigo: item.numeroItem,
        name: item.descricaoItem,
      })),
    }
  },
)

export const createGoalCalculation = createAsyncThunk(
  'goalCalculations/create',
  async (body:any) => {
    const response = await makeRemoteGoalCalculations().create(body)
    return response
  },
)

export const updateGoalCalculation = createAsyncThunk(
  'goalCalculations/update',
  async (body:any) => {
    const { id, data } = body
    const response = await makeRemoteGoalCalculation(id).update(data)
    return response
  },
)

export const goalCalculationClient = createAsyncThunk(
  'goalCalculations/client/create',
  async (params:any) => {
    const { id, divisao } = params
    const body = params
    delete body.id

    const response = await makeRemoteGoalCalculationClient(id, divisao).create(body)
    return response
  },
)

export const goalCalculationItems = createAsyncThunk(
  'goalCalculations/items/create',
  async (params:any) => {
    const { id, divisao } = params
    const body = params
    delete body.id

    const response = await makeRemoteGoalCalculationItem(id, divisao).create(body)
    return response
  },
)

export const goalCalculationProdutosEstrategicos = createAsyncThunk(
  'goalCalculations/produtos-estrategicos/create',
  async (params:any) => {
    const { id, divisao } = params
    const body = params
    delete body.id

    const response = await makeRemoteGoalCalculationProdutoEstrategico(id, divisao).create(body)
    return response
  },
)

const slice = createSlice({
  name: 'goalCalculations',
  initialState,
  reducers: {
    goalCalculationLoaded(state) {
      state.loaded = true
    },
    goalCalculationLoading(state) {
      if (!state?.loading) {
        state.loading = true
      }
    },
    goalCalculationReceived(state) {
      if (state.loading) {
        state.loading = false
      }
    },
    goalCalculationAllGroups(state, action) {
      state.groups = action.payload
    },
    goalCalculationGroupsLoading(state, action) {
      state.loadingGroups = action.payload
    },
    goalCalculationAllItems(state, action) {
      state.items = action.payload
    },
    goalCalculationItemsLoading(state, action) {
      state.loadingItems = action.payload
    },
    changePeriodSelected(state, action) {
      state.selectedYear = action.payload
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchGoalCalculations.fulfilled, (state, action) => {
      state.list = action.payload.content.sort(orderByYear)
    })
    builder.addCase(fetchGoalAllCalculations.fulfilled, (state, action) => {
      state.allList = action.payload.sort(orderByYear)
      state.loading = false
      state.loaded = true
    })
    builder.addCase(fetchGoalAllCalculations.pending, (state) => {
      state.loading = true
    })
  },
})

export const {
  goalCalculationLoaded,
  goalCalculationLoading,
  goalCalculationReceived,
  goalCalculationAllGroups,
  goalCalculationGroupsLoading,
  goalCalculationAllItems,
  goalCalculationItemsLoading,
  changePeriodSelected,
} = slice.actions

export default slice.reducer
